<script>
import { SuawForm, SuawHeading, SuawTextInput, SuawInputGroup, SuawLabel, SuawRadio, SuawSelect, SuawButton, SuawParagraph } from "@suaw/suaw-component-library";
import { ListCountries } from "../../operations.gql";
import { usStates, canadianProvinces } from "../../../../utils/geo/regions.js";
import * as UserApi from "../../api";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "CompleteAccountForm",
  components: { SuawForm, SuawHeading, SuawTextInput, SuawInputGroup, SuawLabel, SuawRadio, SuawSelect, SuawButton, SuawParagraph },
  data() {
    return {
      countries: [],
      completeAccountForm: {
        firstName: "",
        lastName: "",
        countryAbbrev: "",
        isLastNameHidden: true,
        city: "",
        region: "",
        postalCode: ""
      }
    };
  },
  apollo: {
    countries: {
      query: ListCountries
    }
  },
  validations: {
    completeAccountForm: {
      firstName: {
        required,
        maxLength: maxLength(40)
      },
      lastName: {
        required,
        maxLength: maxLength(40)
      },
      countryAbbrev: {
        required
      },
      postalCode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(10)
      }
    }
  },
  computed: {
    formattedCountries() {
      return this.countries.map(country => ({
        text: country.name,
        value: country.abbrev,
        key: country.abbrev
      }));
    },
    regionOptions() {
      if (this.completeAccountForm.countryAbbrev === "US") {
        return usStates;
      } else if (this.completeAccountForm.countryAbbrev === "CA") {
        return canadianProvinces;
      }
      return [];
    }
  },
  mounted() {
    if (this.$auth.user && this.$auth.user.email_verified) {
      this.completeAccountForm.firstName = this.$auth.user.first_name;
      this.completeAccountForm.lastName = this.$auth.user.last_name;
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async completeRegistration() {
      const { firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, city, region } = this.completeAccountForm;
      const result = await UserApi.completeRegistration(this.$auth.user.id, firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, city, region);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onAccountCompleted() {
      this.$v.completeAccountForm.$touch();
      if (this.$v.completeAccountForm.$invalid) {
        this.$root.$emit("universal-error-message", "Please complete the form to finish your account registration.");
        return;
      }
      const completeRegistrationResult = await this.completeRegistration();
      if (!completeRegistrationResult.success) {
        return;
      }

      this.$emit("submit");
    }
  }
};
</script>
<template>
  <SuawForm>
    <template #form>
      <SuawHeading level="3" content="Complete Your Account" alignment="center" />
      <SuawTextInput
        id="first-name-complete-account"
        v-model="completeAccountForm.firstName"
        type="text"
        placeholder="First Name"
        is-required
        label="First Name"
        :state="formFieldState($v, 'completeAccountForm', 'firstName')"
        :error-message="validateErrors($v.completeAccountForm.firstName, 'First name')"
        @blur="$v.completeAccountForm.firstName.$touch()"
      />
      <SuawTextInput
        id="last-name-complete-account"
        v-model="completeAccountForm.lastName"
        type="text"
        placeholder="Last Name"
        is-required
        label="Last Name"
        :state="formFieldState($v, 'completeAccountForm', 'lastName')"
        :error-message="validateErrors($v.completeAccountForm.lastName, 'Last name')"
        @blur="$v.completeAccountForm.lastName.$touch()"
      />
      <SuawInputGroup direction="column" group-gap="base">
        <SuawLabel label-text="Display Name:" weight="bold" size="small" />
        <SuawRadio id="firstLast" v-model="completeAccountForm.isLastNameHidden" name="chooseName" label="First Name & Last Initial" :native-value="true" />
        <SuawRadio id="full" v-model="completeAccountForm.isLastNameHidden" name="chooseName" label="Full Name" :native-value="false" />
      </SuawInputGroup>
      <SuawSelect
        v-model="completeAccountForm.countryAbbrev"
        label="Country"
        placeholder="Select your country"
        is-required
        :options="formattedCountries"
        :state="formFieldState($v, 'completeAccountForm', 'countryAbbrev')"
        :error-message="validateErrors($v.completeAccountForm.countryAbbrev, 'Country')"
        success-message=""
        @blur="$v.completeAccountForm.countryAbbrev.$touch()"
      />
      <SuawSelect
        v-if="completeAccountForm.countryAbbrev === 'US' || completeAccountForm.countryAbbrev === 'CA'"
        id="region"
        v-model="completeAccountForm.region"
        :options="regionOptions"
        label="State/Province (optional)"
        label-weight="bold"
      />
      <SuawTextInput id="city-complete-account" v-model="completeAccountForm.city" type="text" placeholder="Enter city" label="City (optional)" />
      <SuawTextInput
        id="postal-code-complete-account"
        v-model="completeAccountForm.postalCode"
        type="text"
        placeholder="Enter postal code"
        is-required
        label="Postal code"
        :state="formFieldState($v, 'completeAccountForm', 'postalCode')"
        :error-message="validateErrors($v.completeAccountForm.postalCode, 'Postal code')"
        @blur="$v.completeAccountForm.postalCode.$touch()"
      />
      <SuawParagraph
        text="By clicking Finish Account, you accept the <a href='/resources/tos' target='_blank'>Terms of Service</a> and <a href='/resources/code-of-conduct' target='_blank'>Code of Conduct</a>."
      />
      <SuawButton size="large" type="primary" button-text="Finish Account" @click="onAccountCompleted" />
    </template>
  </SuawForm>
</template>
